
import VideoPlayer from '@/components/VideoPlayer/videojs.vue'
import AdminContentEditable from '~/mixins/AdminContentEditable'
import Modal from '@/components/Shared/Modal/index.vue'
import UploadVideoArea from '../HeocademySiteBuilder/UploadVideoArea.vue'
export default {
  mixins: [AdminContentEditable],
  props: {
    modal: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showYoutubeInsertModal: false,
      showUploadVideoModal: false,
      youtubeLink: '',
      showUploadVideo: false,
      video: null,
      st: this.$store.state.settings.contents[this.$i18n.locale],
      showYoutube: '',
      videoOptions: {
        live: false,
        autoplay: this.modal,
        controls: true,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [
          {
            src: this.$store.state.settings.videoPath,
            type: 'video/mp4',
          },
        ],
      },
    }
  },
  methods: {
    FAIL_VIDEO() {
      this.SET_ALERT_BOX(this.$t('acceptVideoFile'), 'danger', 'close')
    },
    GET_FILE(video) {
      this.video = video
    },
    SET_SOURCE() {
      if (
        this.$store.state.settings.videoPath.includes(
          'www.youtube.com/watch?v='
        )
      ) {
        this.showYoutube = this.$store.state.settings.videoPath.split(
          'www.youtube.com/watch?v='
        )[1]
      } else if (this.$store.state.settings.videoPath.includes('youtu.be/')) {
        this.showYoutube =
          this.$store.state.settings.videoPath.split('youtu.be/')[1]
      }
    },
    async SEND_LINK(loading) {
      loading(true)
      // (method, url, data, contentType = '')
      const fd = new FormData()
      fd.append('VideoPath', this.youtubeLink)
      const response = await this.CONTEXT_REQUEST(
        'PUT',
        `Companies/${this.getSettings.id}`,
        fd
      )
      if (response.message === 'OK') {
        this.SET_ALERT_BOX(this.$t('saved'), 'success', 'check')
        setTimeout(() => {
          location.reload()
          loading(false)
        }, 5000)
      } else {
        this.SET_ALERT_BOX(this.$t('unsaved'), 'danger', 'close')
        loading(false)
      }
    },
    async SEND_VIDEO(loading) {
      if (this.video === null) return
      loading(true)
      // (method, url, data, contentType = '')
      const fd = new FormData()
      fd.append('Video', this.video)
      const response = await this.CONTEXT_REQUEST(
        'PUT',
        `Companies/${this.getSettings.id}`,
        fd
      )
      if (response.message === 'OK') {
        this.SET_ALERT_BOX(this.$t('saved'), 'success', 'check')
        setTimeout(() => {
          location.reload()
          loading(false)
        }, 5000)
      } else {
        this.SET_ALERT_BOX(this.$t('unsaved'), 'danger', 'close')
        loading(false)
      }
    },
  },
  components: { VideoPlayer, Modal, UploadVideoArea },
  mounted() {
    this.SET_SOURCE()
  },
}
